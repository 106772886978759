export const navLinks = [
  {
    label: 'PortFolilo',
    pathname: '/',
    hash: '#PortFolilo',
  },
 
  {
    label: 'About',
    pathname: '/',
    hash: '#about',
  },
  {
    label: 'Contact',
    pathname: '/contact',
  },
];

export const socialLinks = [
  {
    label: 'Twitter',
    url: '',
    icon: 'twitter',
  },
  {
    label: 'Dribbble',
    url: '',
    icon: 'dribbble',
  },
  {
    label: 'Github',
    url: '',
    icon: 'github',
  },
];
